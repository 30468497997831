export default {
    methods: {
        isPinValid(pin) {
            var pin_reg = /^[A]{1}[0-9]{9}[a-zA-Z]{1}$/;
            return !!pin.match(pin_reg);
        },
        isIDValid(id) {
            var id_reg = /^[0-9]{8}$/;
            return !!id.match(id_reg);
        },
        isPassportValid(passport) {
            var passport_reg = /^[A-Z0-9]{7,9}$/;
            return !!passport.match(passport_reg);
        },
        checkIdentifier() {
            if (this.form.identifier === "id_number") {
                if (!this.isIDValid(this.form.identity)) {
                    this.error = "Invalid ID Number";
                    return false;
                } else {
                    this.error = "";
                    return true;
                }
            } else if (this.form.identifier === "passport") {
                if (!this.isPassportValid(this.form.identity)) {
                    this.error = "Invalid Passport Number";
                    return false;
                } else {
                    this.error = "";
                    return true;
                }
            } else if (this.form.identifier === "kra_pin") {
                if (!this.isPinValid(this.form.identity)) {
                    this.error = "Invalid KRA PIN";
                    return false;
                } else {
                    this.error = "";
                    return true;
                }
            }
        },
        validateIdentifier() {
            if (this.form.identity === "") {
                this.error = "Identity field is required";
                return false;
            }
            return true;
        },
    }
}
