<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'banlist'}">Banlists</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Create</a></li>
    </ol>
    <h1 class="page-header">Create Relationship Manager</h1>
    <panel noButton="true" title="New Banlist" variant="panel-info">
      <div v-html="$error.handle(error)" />
      <form
        @submit.prevent="validateForm"
      >
        <div class="row">
          <div class="form-group col-md-6">
            <label>Name</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
            <div :class="{'invalid-feedback': errors.has('name')}">
              {{ errors.first('name') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Alias</label>
            <textarea
              v-model="form.alias"
              type="text"
              class="form-control"
              placeholder="Enter comma separated aliases"
            />
          </div>
          <div class="form-group col-md-6">
            <label>Identifier</label>
            <select
              v-model="form.identifier"
              class="form-control"
            >
              <option value="">
                N/A
              </option>
              <option value="id_number">
                ID Number
              </option>
              <option value="passport">
                Passport
              </option>
              <option value="kra_pin">
                KRA PIN
              </option>
              <option value="company_pin">
                Company PIN
              </option>
            </select>
            <div :class="{'invalid-feedback': errors.has('indentifier')}">
              {{ errors.first('indentifier') }}
            </div>
          </div>
          <div
            v-if="form.identifier"
            class="form-group col-md-6"
          >
            <label>Identity ({{ identity_name }})</label>
            <input
              v-model="form.identity"
              class="form-control"
            >
            <div :class="{'invalid-feedback': errors.has('identity')}">
              {{ errors.first('identity') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Banlist</label>
            <input
              v-model="form.banlist"
              class="form-control"
              required
            >
            <div :class="{'invalid-feedback': errors.has('banlist')}">
              {{ errors.first('banlist') }}
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>Type</label>
            <select
              v-model="form.type"
              class="form-control"
            >
              <option value="">
                N/A
              </option>
              <option value="individual">
                Individual
              </option>
              <option value="company">
                Company
              </option>
            </select>
            <div :class="{'invalid-feedback': errors.has('type')}">
              {{ errors.first('type') }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Save
          </button>
        </div>
      </form>
    </panel>
  </div>
</template>

<script>

import identifier from "@/mixins/identifier";

export default {
  mixins: [identifier],
  data() {
    return {
      form: {
        name: '',
        alias: '',
        banlist: '',
        identity: null,
        identifier: null,
        type:null,
      },
      error: '',
      identity_name: ''
    }
  },
  watch: {
    'form.identifier'() {
      if (this.form.identifier === 'id_number') return this.identity_name = 'ID Number';
      if (this.form.identifier === 'passport') return this.identity_name = 'Passport No';
      if (this.form.identifier === 'kra_pin') return this.identity_name = 'KRA PIN';
      if (this.form.identifier === 'company_pin') return this.identity_name = 'Company PIN';
    }
  },
  methods: {
    validateForm() {
      if (!this.validateIdentifier()) return;
      // if (this.form.identity){
      //   if (!this.checkIdentifier()) return;
      // }
      if (!(this.form.alias instanceof Array)) {
        this.form.alias.trim() === '' ? this.form.alias = [] : this.form.alias = this.form.alias.split(',');
      }
      if(this.form.identifier &&  this.form.identifier.trim() === '') this.form.identifier = null;
      if(this.form.type &&  this.form.type.trim() === '') this.form.type = null;
      this.$axios.post('/api/v1/banlist', this.form).then(() => {
        this.$router.push({name: "banlist"});
        this.error = '';
      }).catch(error => {
        this.error = error.response;
      });
    },
  },
}
</script>
